var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"baseInfo"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess,"hasHeader":false},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"投票标题","rules":[
        { required: true, message: '请输入投票标题', trigger: 'blur' },
      ],"prop":"title"}},[_c('v-input',{attrs:{"placeholder":"请输入投票标题","maxlength":20,"width":_vm.width},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"投票说明","prop":"description"}},[_c('div',{attrs:{"id":"editor"}},[_c('v-ueditor',{attrs:{"maxLength":20000},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)]),_c('el-form-item',{attrs:{"label":"回答次数","rules":[
        { required: true, message: '请输入问卷标题', trigger: 'change' },
      ],"prop":"awTimes"}},[_c('v-select',{attrs:{"placeholder":"请选择回答次数","options":_vm.awTimesOps,"width":_vm.width},model:{value:(_vm.form.awTimes),callback:function ($$v) {_vm.$set(_vm.form, "awTimes", $$v)},expression:"form.awTimes"}})],1),_c('el-form-item',{attrs:{"label":"户主限制","rules":[
        { required: true, message: '请选择户主限制', trigger: 'blur' },
      ],"prop":"isOnlyHouseOwner"}},[_c('v-radio',{attrs:{"radioObj":_vm.isOnlyHouseOwnerOps},model:{value:(_vm.form.isOnlyHouseOwner),callback:function ($$v) {_vm.$set(_vm.form, "isOnlyHouseOwner", $$v)},expression:"form.isOnlyHouseOwner"}})],1),_c('el-form-item',{attrs:{"label":_vm.sortLabel,"rules":[{ required: true, message: '请填写排序', trigger: 'blur' }],"prop":"sortIndex"}},[_c('v-input-number',{attrs:{"min":1,"width":_vm.width,"controls":true,"controls-position":"right"},model:{value:(_vm.form.sortIndex),callback:function ($$v) {_vm.$set(_vm.form, "sortIndex", $$v)},expression:"form.sortIndex"}})],1),_c('el-form-item',{attrs:{"label":"用户类别","prop":"authSpaceTypes","rules":[
        { required: true, validator: _vm.spaceTypeValid, trigger: 'blur' },
      ]}},[_c('div',{staticClass:"check-panel"},_vm._l((_vm.userTypeOps),function(item,index){return _c('v-checkbox',{key:index,attrs:{"index":index,"type":"border","label":item.label},on:{"change":_vm.checkChange},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})}),1)]),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.authSpaceTypes !== undefined),expression:"form.authSpaceTypes !== undefined"}],attrs:{"label":"调查区域","prop":"authSpaceIds","rules":[
        { required: false, message: '请选择调查区域', trigger: 'blur' },
      ]}},[_c('v-cascader',{attrs:{"placeholder":"默认表示全部","width":_vm.width,"options":_vm.sectionOps,"props":_vm.props},on:{"change":_vm.sectionChange},model:{value:(_vm.authSpaceIds),callback:function ($$v) {_vm.authSpaceIds=$$v},expression:"authSpaceIds"}})],1),_c('el-form-item',{attrs:{"label":"投票封面图","rules":[
        {
          required: true,
          message: '请上传投票封面图片',
          trigger: 'change',
        },
      ],"prop":"imageUrls"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.imageUrls},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "imageUrls", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "imageUrls", $event)}}})],1),_c('el-form-item',{attrs:{"label":"调查截止时间","rules":[
        { required: true, message: '请输入调查截止时间', trigger: 'blur' },
      ],"prop":"endDate"}},[_c('v-date-picker',{attrs:{"formatValue":"yyyy-MM-dd HH:mm","format":"yyyy-MM-dd HH:mm","type":"datetimerange"},model:{value:(_vm.createTime),callback:function ($$v) {_vm.createTime=$$v},expression:"createTime"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }